<template>
  <div class="usp" :class="{ 'usp--odd-items': uspsData.length % 2 === 1 }">
    <AtomsContainersCornerBorders v-for="usp in uspsData" class="usp__item" :class="props.itemClass">
      <div>
        <AtomsImagesIcon :icon="usp.icon" />
        <div v-html="usp.text"></div>
      </div>
    </AtomsContainersCornerBorders>
  </div>
</template>
<script setup>

const props = defineProps({
  itemClass: String,
});

const appConfig = useAppConfig();
const shopZone = useShopZone().value;
const i18n = useI18n();

let uspsData = [
  {
    icon: 'truck',
    text: i18n.t('Doprava zdarma od 2&nbsp;500 Kč'),
  },
  {
    icon: 'package',
    text: i18n.t('97% zboží skladem'),
  },
  {
    icon: 'hand-coin',
    text: i18n.t('Garance vrácení peněz'),
  },
  {
    icon: 'store2',
    text: i18n.t('Kamenné prodejny'),
  },
];

uspsData = uspsData.filter((usp) => usp.text);

</script>
<style lang="postcss">
.usp {
  @apply flex-1 flex flex-wrap gap-4;

  &__item {
    @apply flex items-center justify-center w-[calc(50%-0.5rem)] p-4 text-sm text-center text-primary-500 bg-neutral-50 tablet:flex-1;

    .icon {
      @apply block mb-2 text-lg tablet:text-3xl;
    }
  }

  &--odd-items &__item {
    @apply px-2 min-w-[calc(32.5%-0.5rem)] flex-1;
  }
}
</style>